<template>
  <div class="my-10">
    <div class="w-full">
      <div class="flex items-center gap-3">
        <h1 class="w-2/5">Staff Name</h1>
        <h1 class="w-2/5">Email</h1>
        <h1 class="w-1/5">Phone Number</h1>
        <h1 class="w-24">Gender</h1>
        <h1 class="w-1/5"></h1>
      </div>
      <div
        class="flex items-center gap-3 bg-white py-3 px-5 rounded-md my-4 hover:shadow-md cursor-pointer"
        v-for="staff in staffs"
        :key="staff.id"
        @click="$emit('edit', staff)"
      >
        <div class="w-2/5 flex items-center gap-3">
          <img
            :src="staff.image"
            class="w-auto h-10 rounded-full"
            :placeholder="staff.firstname"
          />
          <div class="w-2/3">
            <span class="uppercase">{{ staff.firstname }}</span>
            {{ staff.lastname }}
          </div>
        </div>
        <div class="w-2/5">
          {{ staff.email }}
        </div>
        <div class="w-1/5">
          {{ staff.phone }}
        </div>
        <div
          class="w-24 bg-opacity-30 py-2 px-5 text-xs text-center rounded-full truncate text-ellipse"
          :class="staff.gender === 'female' ? 'bg-red-300 text-red-500' : 'bg-blue-300 text-blue-500'"
        >
          {{ staff.gender }}
        </div>
        <div class="w-1/5 relative menu">
          <div class="flex ml-10">
            <div
              class="bg-blue-300 bg-opacity-10 text-blue-400 font-semibold p-2 rounded-lg flex items-center"
              @click="$emit('edit', staff)"
            >
              <edit-icon class="mr-3" />
              View
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-end gap-3">
      <template v-for="page in Math.ceil(total / perPage)">
        <div
          class="bg-white py-2 px-4 cursor-pointer rounded-md"
          :key="page"
          :class="
            page === currentPage
              ? 'bg-primary cursor-default text-white shadow-md'
              : ''
          "
          @click="$emit('fetchPage', page)"
        >
          {{ page }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "staffTable",
  components: {
    EditIcon: () => import("@/assets/icons/EditIcon.vue"),
  },
  props: {
    allStaff: {
      required: true,
      type: Array,
    },
    perPage: {
      required: true,
      type: Number,
    },
    total: {
      required: true,
      type: Number,
    },
    currentPage: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      pageNumber: 0,
    };
  },
  computed: {
    staffs() {
      return this.allStaff;
    },
  },
};
</script>

<style>
.menu:hover .hidden {
  display: block;
}
</style>
